@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  font-family: "Raleway", sans-serif;
}
.logoImg {
  height: 30px;
}


input {
  transition: all 200ms ease-in-out;
  outline: none;
  border-bottom: 1px solid #000;
}

input:focus {
  outline: none;
  border-bottom: 1px solid #ff7b3a;
}

.slider-title{
  font-size: 26px;
}
.slider_div,
.slider-wrapper {
  display: grid;
  place-items: center;
  width: 100%;
}

.center_div {
  width: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 2%;
}

.category-slider {
  position: relative;
  transition: transform 0.3s ease-in;
}

.category-img {
  padding: 20px;
  border-radius: 10%;
}



.slider-title {
  font-family: "Montserrat", sans-serif;
  border-radius: 30px;
  position: absolute;
  bottom: 8%;
  right: 10%;
  width: 80%;
  text-align: center;
  align-items: center;
  font-size: 2.5em;
  background-color: #000000;
  color: white;
  opacity: 0.7;
  overflow: hidden;
  transition: height 2s, opacity 1s;
}

.category-slider:hover {
  transform: scale(1.1);
}

.center_div {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.category-slider {
  flex: 0 0 auto;
  margin-right: 15px;
}

.category-slider:hover .slider-title {
  opacity: 1;
}

.slider_div .center_div::-webkit-scrollbar {
  display: none;
}

.slider_div .slider-scrollbar {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}

.slider-wrapper {
  position: relative;
}

.slider-wrapper .slide-button:hover {
  background: #444;
}

.slider-wrapper button#prev-slide {
  right: 5px;
}

/* lists  */
.olcards {
  list-style: none;
  counter-reset: cardCount;
  font-family: sans-serif;
  flex-direction: column;
  --cardsGap: 1rem;
  gap: var(--cardsGap);
  padding-bottom: var(--cardsGap);
}
.olcards li {
  counter-increment: cardCount;
  color: white;
  --labelOffset: 1rem;
  --arrowClipSize: 1.5rem;
}

.olcards li .content {
  --inlinePadding: 1em;
  --boxPadding: 0.5em;
  display: grid;
  padding: var(--boxPadding) calc(var(--inlinePadding) + var(--arrowClipSize))
    var(--boxPadding) calc(var(--inlinePadding) + var(--labelOffset));
  grid-template-areas:
    "icon title"
    "icon text";
  gap: 0.25em 1em;
  clip-path: polygon(
    0 0,
    calc(100% - var(--arrowClipSize)) 0,
    100% 50%,
    calc(100% - var(--arrowClipSize)) 100%,
    calc(100% - var(--arrowClipSize)) calc(100% + var(--cardsGap)),
    0 calc(100% + var(--cardsGap))
  );
  position: relative;
}
.olcards li .content::before {
  content: "";
  position: absolute;
  width: var(--labelOffset);
  height: var(--labelOffset);
  background: var(--cardColor);
  left: 0;
  bottom: 0;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: brightness(0.75);
}
.olcards li .content::after {
  content: "";
  position: absolute;
  height: var(--cardsGap);
  width: var(--cardsGap);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.25), transparent 50%);
  left: 0;
  top: 100%;
}