.rs-banner {
    // &.style1 {
    //     padding: 125px 0 425px;        
    //     background-size: cover;
    //     background-position: center;
    //     .banner-content {
    //         max-width: 750px;
    //         margin: 0 auto;
    //         .banner-title {
    //             font-size: 55px;
    //             margin-bottom: 16px;
    //         }
    //         .desc {
    //             font-size: 20px;
    //             line-height: 30px;
    //         }
    //     }
    // }
    // &.style3 {
    //     background-size: cover;
    //     background-position: center;
    //     height: 100vh;
    //     display: grid;
    //     align-items: center;
    //     .banner-content {
    //         .banner-title {
    //             font-size: 70px;
    //             line-height: 1.2;
    //             margin-bottom: 19px;
    //         }
           
    //         .banner-desc {
    //             font-size: 18px;
    //             line-height: 30px;
    //             margin-bottom: 41px;
    //             position: relative;
    //             z-index: 9;
    //         }
    //         .banner-btn {
    //             li {
    //                 display: inline;
    //                 margin-right: 30px;
    //                 &:last-child {
    //                     margin: 0;
    //                 }
    //             }
    //         }
    //         .banner-image {
    //             position: absolute;
    //             right: 50px;
    //             top: 52%;
    //             transform: translateY(-50%);
    //         }
    //     }
    // }
    &.style4 {
        background-size: cover;
        background-position: center;
        // display: grid;
        align-items: center;
        .banner-content {
            .banner-title {
                font-size: 35px;
                line-height: 50px;
                font-weight: 600;
                margin-bottom: 30px;
                margin-top: 20px;
                color:#fd6c24
            }
            .desc{
                font-size: 22px;
                font-weight: 500;
            }
        }
        .banner-line-shape {
            position: absolute;
            top: -205px;
            left: 53%;
            transform: translateX(-47%);
        }
        .banner-circle-shape {
            text-align: right;
            position: absolute;
            bottom: -110px;
            right: -15px;
        }
        .banner-dots-shape {
            position: absolute;
            bottom: -220px;
            left: -200px;
        }
        // .teacher-image {
        //     // width: 500px;
        //     position: absolute;
        //     right: 0px;
        //     overflow: hidden;
        //     bottom: -100px;
        //     left: 690px;
        // }
    }
    &.teacherBanner {
        // background-size: cover;
        // background-position: center;
        // // display: grid;
        // align-items: center;
          // height: 500px;
          position: relative;
          // background-color: #f3fafc;
          background-image: url('../img/abacus/Teacher/abacus-bg.jpg');
         background-size: cover;
         background-repeat: no-repeat;
         height: 450px;
          display: grid;
          // align-items: center top;
          z-index: 9;
        .banner-content {
            position: absolute;
            top: 100px;
            .banner-title {
                font-size: 35px;
                line-height: 50px;
                font-weight: 800;
                margin-bottom: 30px;
                // text-shadow: 0px 3px 3px black;
            }
            // .desc{
            //     font-size: 22px;
            //     font-weight: 500;
            // }
        }
        .banner-line-shape {
            position: absolute;
            top: -205px;
            left: 53%;
            transform: translateX(-47%);
        }
        .banner-circle-shape {
            text-align: right;
            position: absolute;
            bottom: -110px;
            right: -15px;
        }
        .banner-dots-shape {
            position: absolute;
            bottom: -220px;
            left: -200px;
        }
        .teacher-image {
            width: 800px;
            position: absolute;
            top :45px;  
            // overflow: hidden;
            // bottom: -100px;
            right: -110px;
        }
    }
    &.BannerMath {
        // background-size: cover;
        // background-position: center;
        // // display: grid;
        // align-items: center;
          // height: 500px;
          position: relative;
          // background-color: #f3fafc;
          background-image: url('../img/abacus/abacus-bg-site.343fa27a-min.jpg');
         background-size: cover;
         background-repeat: no-repeat;
         min-height: 400px;
          display: grid;
          // align-items: center top;
          z-index: 9;
        .banner-content {
            position: absolute;
            top: 130px;
            left: 50px;
            text-align: center;
            .banner-title {
                font-size: 35px;
                line-height: 50px;
                font-weight: 900;
                margin-bottom: 30px;
            }
            // .desc{
            //     font-size: 22px;
            //     font-weight: 500;
            // }
        }
        .banner-line-shape {
            position: absolute;
            top: -205px;
            left: 53%;
            transform: translateX(-47%);
        }
        .banner-circle-shape {
            text-align: right;
            position: absolute;
            bottom: -110px;
            right: -15px;
        }
        .banner-dots-shape {
            position: absolute;
            bottom: -150px;
            left: -200px;
        }
        .teacher-image {
            // width: 800px;
            position: absolute;
            top :40px;  
            // overflow: hidden;
            // bottom: -100px;
            right: -80px;
        }
    }
    &.BannerResearch {
        // background-size: cover;
        // background-position: center;
        // // display: grid;
        // align-items: center;
          // height: 500px;
          position: relative;
          // background-color: #f3fafc;
          background-image: url('../img/abacus/Research/wood-img.jpg');
         background-size: cover;
         background-repeat: no-repeat;
         min-height: 375px;
          display: grid;
          // align-items: center top;
          z-index: 9;
        .banner-content {
            position: absolute;
            top: 80px;
            // left: 30px;
            text-align: center;
            .banner-title {
                font-size: 30px;
                line-height: 40px;
                font-weight: 800;
                margin-bottom: 10px;
            }
            // .desc{
            //     font-size: 22px;
            //     font-weight: 500;
            // }
        }
        .banner-line-shape {
            position: absolute;
            top: -205px;
            left: 53%;
            transform: translateX(-47%);
        }
        .banner-circle-shape {
            text-align: right;
            position: absolute;
            bottom: -110px;
            right: -15px;
        }
        .banner-dots-shape {
            position: absolute;
            bottom: -150px;
            left: -200px;
        }
        // .teacher-image {
        //     width: 800px;
        //     position: absolute;
        //     top :40px;  
        //     overflow: hidden;
        //     bottom: -100px;
        //     right: -20px;
        // }
    }
    &.franchiseBanner {
        // position: relative;
        // background-image: url('../img/abacus/whatAbacus/what-abacus-banner.jpg');
       background-size: cover;
       background-repeat: no-repeat;
    //    height: 350px;
        display: grid;
        // align-items: center top;
        z-index: 9;
        background-size: cover;
        background-position: center;
        // display: grid;
        align-items: center;
        .banner-content {
            position: absolute;
            bottom: 160px;
            // top: 100px;
            left: 0px;
            .banner-title {
                font-size: 35px;
                line-height: 50px;
                font-weight: 700;
                margin-bottom: 30px;
            }
            .desc{
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 20px;
            }
        }
        .banner-line-shape {
            position: absolute;
            top: -205px;
            left: 53%;
            transform: translateX(-47%);
        }
        .banner-circle-shape {
            text-align: right;
            position: absolute;
            bottom: -110px;
            right: -15px;
        }
        .banner-dots-shape {
            position: absolute;
            bottom: -220px;
            left: -200px;
        }
        .teacher-image {
            // width: 500px;
            position: absolute;
            right: 0px;
            overflow: hidden;
            bottom: -100px;
            left: 690px;
        }
    }
    &.style5 {
        // height: 500px;
        position: relative;
        // background-color: #f3fafc;
        background-image: url('../img/abacus/abacus-bg-site.343fa27a-min.jpg');
       background-size: cover;
       background-repeat: no-repeat;
       min-height: 380px;
        display: grid;
        // padding-right: 30px;
        // align-items: center top;
        z-index: 9;
        .banner-content {
            // padding-bottom: 100px;
            // position: absolute;
            // top: 30px;
            margin-top: 30px;
            margin-left: 30px;
            // left: 30px;
            // margin-top: -100px;
            .sub-title {    
                color: $titleColor;
                margin-top: 0px;
                font-size: 26px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 40px;
            }

            .title-style{
                color: #F58634;
            }
            .banner-text{
                background-color: #ffffff;
                color: #F58634;
                padding-inline: 8px;
            }
            .banner-title {
                color: $titleColor;
                font-family: "Poppins";
                font-size: 40px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 45px;
            } 
            
            .sub-title2 {
                font-size: 22px;
                color: black;
                margin-top: 30px;
            }
        }
        .banner-img {
            position: absolute;
            top : 20px;     
            // left: 20px;
            // right: 50px;
            // bottom: 0;
            // width: ;
        }
    }
    &.BannerAbout {
        // height: 500px;
        position: relative;
        // background-color: #f3fafc;
        background-image: url('../img/abacus/abacus-bg-site.343fa27a-min.jpg');
       background-size: cover;
       background-repeat: no-repeat;
       min-height: 380px;
        display: grid;
        // align-items: center top;
        z-index: 9;
        .banner-content {
            // padding-bottom: 100px;
            position: absolute;
            top: 100px;
            // margin-top: -100px;
            .sub-title {    
                color: $titleColor;
                margin-top: 20px;
                font-size: 22px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 20px;
            }
            .banner-title {
                color: $titleColor;
                font-size: 30px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 39px;
            }
        }
        .banner-img {
            position: absolute;
            top : 35px;  
            // bottom: 0;
            // width: ;
        }
    }
    &.BannerFranchiseFaq {
        // height: 500px;
        position: relative;
        // background-color: #f3fafc;
        background-image: url('../img/abacus/franchiseFaq/faq-banner.png');
       background-size: cover;
       background-repeat: no-repeat;
       min-height: 380px;
        display: grid;
        // align-items: center top;
        z-index: 9;
        .banner-content {
            // padding-bottom: 100px;
            position: absolute;
            top: 100px;
            // margin-top: -100px;
            .sub-title {    
                color: $titleColor;
                margin-top: 20px;
                font-size: 30px;
                line-height: 24px;
                font-weight: 700;
                margin-bottom: 20px;
            }
            .banner-title {
                color: $titleColor;
                font-size: 30px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 39px;
            }
        }
        .banner-img {
            position: absolute;
            top : 20px;  
            // bottom: 0;
            // width: ;
        }
    }
    &.schoolBanner{
        // height: 500px;
        position: relative;
        // background-color: #f3fafc;
        background-image: url('../img/abacus/abacus-bg-site.343fa27a-min.jpg');
       background-size: cover;
       background-repeat: no-repeat;
       min-height: 380px;
        display: grid;
        // align-items: center top;
        z-index: 9;
        .banner-content {
            padding-bottom: 100px;
            position: absolute;
            top: 50px;
            // margin-top: -100px;
            .sub-title {    
                color: $titleColor;
                margin-top: 20px;
                font-size: 22px;
                line-height: 35px;
                font-weight: 400;
                margin-bottom: 20px;
            }
            .banner-title {
                // color: $titleColor;
                font-size: 35px;
                line-height: 50px;
                font-weight: 800;
                // color: #fd6c24;
                margin-bottom: 0px;
            }
        }
        .banner-img {
            position: absolute; 
            bottom: 0;
        }
    }
    &.whatAbacus {
        // height: 500px;
        position: relative;
        // background-color: #f3fafc;
        background-image: url('../img/abacus/whatAbacus/what-abacus-banner.jpg');
       background-size: cover;
       background-repeat: no-repeat;
       min-height: 400px;
        display: grid;
        // align-items: center top;
        z-index: 9;
        .banner-content {
            // padding-bottom: 100px;
            position: absolute;
            top: 40px;
            // margin-top: -100px;
            .sub-title {    
                color: white;
                margin-top: 20px;
                font-size: 20px;
                line-height: 30px;
                font-weight: 400;
                margin-bottom: 20px;
            }
            .banner-title {
                color: white;
                font-size: 30px;
                line-height: 30px;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
        .banner-img {
            position: absolute;
            top : 20px;  
            // bottom: 0;
            // width: ;
        }
    }
    &.style6 {
        margin-bottom: 40px;
        .container {
            position: relative;
        }
        .banner-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 100px 85px;
            .banner-title {
                font-weight: 900;
                line-height: 50px;
                font-size:  30px;
            }
            .desc {
                font-size: 18px;
                line-height: 30px;
            }
        }
        .shape-img{
            position: absolute;
            bottom: -54px;
            &.left{
                left: 15px;
                z-index: 1;
            } 
            &.center{
                left: 50%;
                transform: translateX(-50%); 
                .inner {
                    width: 810px;
                    height: 385px;
                    margin: 0 auto;
                    position: relative;
                    .spiner {
                        position: absolute;
                        content: '';
                        &.one {
                            top: 0;
                            left: 37%;
                            img {
                                &:nth-child(1) {
                                    position: relative;
                                    right: 4px;
                                    top: 9px;
                                }
                            }
                        }
                        &.two {
                            top: 56%;
                            left: 0;
                            img {
                                &:nth-child(1) {
                                    max-width: 16px;
                                }
                                &:nth-child(2) {
                                    position: relative;
                                    right: -3px;
                                    top: -4px;
                                    max-width: 25px;
                                }
                            }
                        }
                        &.three {
                            top: 40%;
                            right: 0;
                            img {
                                &:nth-child(1) {
                                    max-width: 16px;
                                    position: relative;
                                    top: 29px;
                                    left: 31px;
                                }
                                &:nth-child(2) {
                                    position: relative;
                                    right: 7px;
                                    top: 1px;
                                    max-width: 25px;
                                }
                            }
                        }
                    }
                }
            } 
            &.right{
               right: 15px; 
            }
        }
    }
    &.bannerInvetment {
        background-image: url('../img/abacus/investment/franchisee_page_2_banner.jpg');
        background-size: cover;
        background-position: center;
        height: 300px;
        margin-bottom: 40px;
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .banner-content {
            position: absolute;
            top: 100px;
            // left: 0px;
            // display: flex;
            // // flex-direction: column;
            // align-items: center;
            // justify-content: center;
            // padding: 100px 85px;
            .banner-title {
                font-weight: 900;
                line-height: 50px;
                font-size:  30px;
            }
            // .desc {
            //     font-size: 18px;
            //     line-height: 30px;
            // }
        }
        // .shape-img{
        //     position: absolute;
        //     bottom: -54px;
        //     &.left{
        //         left: 15px;
        //         z-index: 1;
        //     } 
        //     &.center{
        //         left: 50%;
        //         transform: translateX(-50%); 
        //         .inner {
        //             width: 810px;
        //             height: 385px;
        //             margin: 0 auto;
        //             position: relative;
        //             .spiner {
        //                 position: absolute;
        //                 content: '';
        //                 &.one {
        //                     top: 0;
        //                     left: 37%;
        //                     img {
        //                         &:nth-child(1) {
        //                             position: relative;
        //                             right: 4px;
        //                             top: 9px;
        //                         }
        //                     }
        //                 }
        //                 &.two {
        //                     top: 56%;
        //                     left: 0;
        //                     img {
        //                         &:nth-child(1) {
        //                             max-width: 16px;
        //                         }
        //                         &:nth-child(2) {
        //                             position: relative;
        //                             right: -3px;
        //                             top: -4px;
        //                             max-width: 25px;
        //                         }
        //                     }
        //                 }
        //                 &.three {
        //                     top: 40%;
        //                     right: 0;
        //                     img {
        //                         &:nth-child(1) {
        //                             max-width: 16px;
        //                             position: relative;
        //                             top: 29px;
        //                             left: 31px;
        //                         }
        //                         &:nth-child(2) {
        //                             position: relative;
        //                             right: 7px;
        //                             top: 1px;
        //                             max-width: 25px;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     } 
        //     &.right{
        //        right: 15px; 
        //     }
        // }
    }
    // &.style7{
    //    background-size: cover;
    //    background-position: center;
    //    position: relative;
    //    min-height: 628px;
    //    .banner-content{
    //     position: relative;
    //     padding: 200px 0;  
    //         .banner-title{
    //             font-size: 70px;
    //             line-height: 80px;
    //             font-weight: 800;

    //         }          
    //    }
    //     .icons {
    //         position: absolute;
    //         &.one{
    //             top: 136px;
    //             right: 50px;
    //         } 
    //         &.two{
    //             left: 50%;
    //             bottom: 100px;
    //         } 
    //         &.three{
    //             top: 95px;
    //             left: -160px;
    //         } 
    //         &.four{
    //             bottom: 210px;
    //             left: -62px;
    //         }
    //     }
    //     .img-part{
    //         position: absolute;
    //         right: 0;
    //         bottom: 88px;
    //     }
    // }
    // &.style8 {
    //     background-size: cover;
    //     background-position: center;
    //     display: grid;
    //     align-items: center;
    //     min-height: 960px;
    //     .banner-content {
    //         margin-top: 30px;
    //         .sl-sub-title {
    //             font-size: 48px;
    //             line-height: 50px;
    //             font-weight: 400;
    //             color: $whiteColor;
    //             margin-bottom: 22px;
    //         }
    //         .sl-title {
    //             font-size: 90px;
    //             line-height: 90px;
    //             font-weight: 700;
    //             color: $whiteColor;
    //             padding-right: 374px;
    //             margin-bottom: 45px;
    //         }
    //     }
    // }
    // &.style9{
    //     background-size: cover;
    //     display: grid;
    //     align-items: center;
    //     min-height: 880px;
    //     position:  relative;
    //     .banner-content{
    //         max-width: 615px;
    //         .banner-title{
    //             font-size: 70px;
    //             line-height: 1.3;
    //             color: $purpleColor;
    //         }
    //         .desc {
    //             font-size: 18px;
    //             line-height: 32px;
    //             font-weight: 400;
    //             color: $purpleColor; 
    //         }
    //     }
    //     .shape-img{
    //         .spiner{
    //             position: absolute;
    //             bottom: 0;
    //             &.one{
    //                 top: -40%;
    //                 right: 70%;
    //             } 
    //             &.two{
    //                left: 56%;
    //             } 
    //             &.three{
    //                 left: 290px;
    //                 bottom: -118px;
    //             }
    //             &.four{
    //                 top: 0%;
    //                 left: -14%;
    //             }
    //         }
    //     }
    //     .social-icon{
    //         position: absolute;
    //         bottom: 35px;
    //         left: 70px;
    //         .icon-cart{
    //             li{
    //                 display: inline;
    //                 margin-right: 8px;
    //                 a{
    //                     display: inline-block;
    //                     width: 40px;
    //                     height: 40px;
    //                     line-height: 44px;
    //                     border-radius: 100%;
    //                     background: $whiteColor;
    //                     color: $purpleColor;
    //                     text-align: center;
    //                     &:hover{
    //                         color: $primaryColor;
    //                     }
    //                 }
    //                 i{
    //                     font-size: 20px;
    //                 }
    //             }
    //         }
    //     }
    // }
    &.style10{
        background-repeat: no-repeat;
        background-size: cover;
        display: grid;
        align-items: center;
        min-height: 700px;
        position: relative;
        .banner-content{
            // padding: 120px 0 100px;
            .sl-sub-title{
                font-size: 20px;
                line-height: 25px;
                font-weight: 400;
                color: black;
                text-transform: uppercase;
                padding-bottom: 30px;
            }
            .sl-title{
                font-size: 40px;
                line-height: 50px;
                font-weight: 800;
                color: #fd6c24;
                // text-shadow: 0px 3px 3px black;
                text-transform: uppercase;
                padding-bottom: 25px;
            }
        }
        // .img-part{
        //     width: 550px;
        //     position: absolute;
        //     top: 70px;
        //     left: 0%;
        //     z-index: 111;
        // }
        .banner-intro-box{
            .shape-img{
                img{
                    width: 150px;
                }
                position: absolute;
                top: 55px;
                left:45px;
            }
            .intro-img{
                img{
                    width: 600px;
                }
                position: absolute;
                right: 9%;
                top: 50px;
                .img-part{
                    width: 100%;
                    position: relative;
                    bottom: 600px;
                    // left: 2%;
                    z-index: 111;
                    // img {
                    //     // width: 500px;
                    // }
                }
            }
        }
    }
    // &.style11{
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     position: relative;
    //     display: grid;
    //     align-items: center;
    //     min-height: 950px;
    //     z-index: 1;
    //     .content-part{
    //        padding: 180px 0 100px;
    //         .sub-title{
    //             font-size: 16px;
    //             line-height: 27px;
    //             font-weight: 400;
    //             color: $orangeColor;
    //             text-transform: uppercase;
    //             display: block;
    //             margin-bottom: 15px;
    //         }
    //         .title{
    //             font-size: 70px;
    //             line-height: 75px;
    //             font-weight: 700;
    //             color: $whiteColor;
    //             margin-bottom: 32px;
    //         }
    //     }
    //     .images-part{
    //         padding-top: 100px;
    //         img{
    //             max-width: unset;
    //             width: 700px;
    //         }
    //     }
    //     .icons-spiner{
    //         .circle{
    //             top: 18%;
    //             right: 81%;
    //             position: absolute;
    //         }
    //         .squre-img{
    //             position: absolute;
    //             right: 60%;
    //             bottom: 20%;
    //         }
    //         .dot-img{
    //             position: absolute;
    //             right: 15%;
    //             top: 20%;
    //             z-index: -111;
    //         }
    //     }
    // }
    // &.style12{
    //     background-color: #F9F7F8;
    //     background-position: bottom left;
    //     background-repeat: no-repeat;
    //     padding: 100px 0px 100px 0px;
    //     .banner-content{
    //         .sub-text{
    //             font-size: 16px;
    //             line-height: 27px;
    //             font-weight: 500;
    //             color: $orangeColor;
    //             text-transform: uppercase;
    //             display: block;
    //             margin-bottom: 10px;
    //         }
    //         .title{
    //             font-size: 48px;
    //             line-height: 62px;
    //             font-weight: 800;
    //             color: $titleColor5;
    //             margin-bottom: 30px;
    //             span{
    //                 color: $orangeColor;
    //             }
    //         }
    //         .desc{
    //             font-size: 20px;
    //             line-height: 32px;
    //             font-weight: 400;
    //             color: $titleColor6;
    //             margin-bottom: 45px;
    //         }
    //         .search-widget{
    //             .search-wrap{
    //                 position: relative;
    //                 [type=search]{
    //                     outline: none;
    //                     padding: 20px 30px;
    //                     border: none;
    //                     border-radius: 3px;
    //                     box-shadow: none;
    //                     padding-right: 77px;
    //                     width: 100%;
    //                 }
    //                 button{
    //                     background: transparent;
    //                     border: medium none;
    //                     color: $orangeColor;
    //                     padding: 11px 15px 12px;
    //                     position: absolute;
    //                     display: block;
    //                     right: 10px;
    //                     top: 10px;
    //                     z-index: 10;
    //                     font-size: 20px;
    //                     font-weight: 700;
    //                 }
    //             }
    //         }
    //     }
    //     .banner-img{
    //         img{
    //             max-width: unset;
    //             width: 620px;
    //         }
    //     }
    // }
    // &.style13{
    //     background-position: center center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     padding: 136px 0px 0px 0px;
    //     position: relative;
    //     z-index: 111;
    //     overflow: hidden;
    //     .banner-content{
    //         padding: 30px 0 0;
    //         .sub-text{
    //             font-size: 16px;
    //             font-weight: 700;
    //             color: $orangeColor;
    //             text-transform: uppercase;
    //             font-family: "Nunito", Sans-serif;
    //             letter-spacing: 1px;
    //             display: block;
    //             margin-bottom: 10px;
    //         }
    //         .title{
    //             font-size: 52px;
    //             line-height: 68px;
    //             font-weight: 700;
    //             color: $whiteColor;
    //             margin-bottom: 30px;
    //         }
    //         .desc{
    //             font-size: 20px;
    //             line-height: 32px;
    //             font-weight: 400;
    //             color: $whiteColor;
    //             margin-bottom: 35px;
    //         }
    //         .search-widget{
    //             .search-wrap{
    //                 position: relative;
    //                 [type=search]{
    //                     outline: none;
    //                     padding: 20px 30px;
    //                     border: none;
    //                     border-radius: 3px;
    //                     box-shadow: none;
    //                     padding-right: 77px;
    //                     width: 100%;
    //                 }
    //                 button{
    //                     background: transparent;
    //                     border: medium none;
    //                     color: $orangeColor;
    //                     padding: 11px 15px 12px;
    //                     position: absolute;
    //                     display: block;
    //                     right: 10px;
    //                     top: 10px;
    //                     z-index: 10;
    //                     font-size: 20px;
    //                     font-weight: 700;
    //                     i{
    //                         &:before{
    //                             font-size: 20px;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //         ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    //           color: #363636;
    //           opacity: 0.3;
    //         }
    //         ::-moz-placeholder { /* Firefox 19+ */
    //           color: #363636;
    //           opacity: 0.3;
    //         }
    //         :-ms-input-placeholder { /* IE 10+ */
    //           color: #363636;
    //           opacity: 0.3;
    //         }
    //         :-moz-placeholder { /* Firefox 18- */
    //           color: #363636;
    //           opacity: 0.3;
    //         } 
    //     }
    //     .banner-img{
    //         img{
    //             position: relative;
    //             max-width: 925px;
    //             right: 5%;
    //             bottom: -8px;
    //         }
    //     }
    //     .rs-animation-part{
    //         .animate-style{
    //             position: absolute;
    //             bottom: 0;
    //             z-index: -111;
    //             &.one{
    //                left: 10%;
    //                top: 42%;
    //             }
    //             &.two{
    //                 left: 10%;
    //                 bottom: 10%;
    //             }
    //             &.three{
    //                left: 30%;
    //                top: 3%;
    //             }
    //             &.four{
    //                left: 48%;
    //                top: 10%;
    //             } 
    //             &.five{
    //                 right: 25%;
    //                 top: 17%;
    //             } 
    //             &.six{
    //                 left: 12%;
    //                 top: 40%;
    //             }
    //         } 
    //     }
    // }
    .left-shape {
        position: absolute;
        top: -30px;
        left: 40px;
        z-index: -1;
    }
    .right-shape {
        position: absolute;
        top: 65px;
        right: -90px;
        z-index: -1;
    }

    .bottom-shape {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        img {
            width: 100%;
        }
    }
}



/* Laptop :1600px. */
@media #{$laptop} {
    
}

/* Custom Container Width :1500px. */
@media (max-width: 1500px) {
    .rs-banner.style5 .banner-content .banner-title {
        font-size: 40px;
    }
    .rs-banner .left-shape {
        top: 30px;
        left: unset;
        right: -150px;
    }
    
}

/* Custom Container Width :1400px. */
@media (max-width: 1400px) {
    // .rs-banner.style3 .banner-content .banner-title {
    //     font-size: 45px;
    // }
    // .rs-banner.style3 .banner-content .banner-image img {
    //     max-width: 560px;
    // }
    .rs-banner .left-shape {
        top: 50px;
    }
    // .rs-banner.style8 .banner-content .sl-title {
    //     color: #ffffff;
    //     padding-right: 0;
    // }
}

/* XL Device :1366px. */
@media #{$xl} {
    // .rs-banner.style4 {
    //     // height: 900px;
    // }
    .rs-banner.style4 .banner-content .banner-title {
        font-size: 35px;
    }
    .rs-banner.style5 {
        min-height: 350px;
    }
    // .rs-banner.style13 .banner-content .title {
    //     font-size: 46px;
    //     line-height: 59px;
    // }
    // .rs-banner.style13 .rs-animation-part .animate-style.two {
    //     left: 4%;
    //     bottom: 10%;
    // }
    // .rs-banner.style13 .rs-animation-part .animate-style.one {
    //     left: 4%;
    //     top: 35%;
    // }
    // .rs-banner.style8 .banner-content {
    //     max-width: 600px;
    // }
    // .rs-banner.style8 .banner-content .sl-title {
    //     font-size: 60px;
    //     line-height: 1.3;
    // }
    // .rs-banner.style8 .banner-content .sl-sub-title {
    //     font-size: 36px;
    //     line-height: 1.1;
    // }
    .rs-banner.style10 .banner-content .sl-title {
        font-size: 38px;
        line-height: 1.5;
    }
    
}

/* Lg Device :1199px. */
@media #{$lg} {
    // .rs-banner.style13 .banner-content .search-widget .search-wrap [type="search"] {
    //     padding: 20px 19px;
    //     padding-right: 0px;
    // }
    // .rs-banner.style11 .content-part .title {
    //     font-size: 54px;
    //     line-height: 64px;
    // }
    // .rs-banner.style11 .icons-spiner .dot-img img {
    //    width: 170px;
    // }
    // .rs-banner.style11 .icons-spiner .dot-img {
    //     right: 5%;
    //     top: 23%;
    // }
    // .rs-banner.style11 .images-part img {
    //     width: 500px;
    // }
    .rs-banner.style10 .banner-intro-box .shape-img img {
        width: 80px;
    }
    .rs-banner.style10 {
        min-height: 750px;
    }
    .rs-banner.style10 .banner-content .sl-title {
        font-size: 38px;
        line-height: 60px;
    }
    .rs-banner.style10 .banner-intro-box .intro-img img {
        width: 500px;
    }
    .rs-banner.style10 .banner-intro-box .intro-img img {
        width: 400px;
    }
    .rs-banner.style10 .banner-intro-box .intro-img {
        right: 0%;
        top: 62px;
    }
    .rs-banner.style10 .img-part {
        max-width: 320px;
        position: absolute;
        top: 50px;
        left: unset;
        right: 0;
        z-index: 111;
    }
    // .rs-banner.style13 .rs-animation-part .animate-style.five {
    //     right: 17%;
    //     top: 4%;
    // }
    // .rs-banner.style13 .rs-animation-part .animate-style.three {
    //     left: 30%;
    //     top: 3%;
    // }
    // .rs-banner.style13 .rs-animation-part .animate-style.two {
    //     left: 3%;
    //     bottom: 3%;
    // }
    // .rs-banner.style13 .rs-animation-part .animate-style.one {
    //     left: 3%;
    //     top: 13%;
    // }
    // .rs-banner.style9 .banner-content .banner-title {
    //     font-size: 60px;
    //     line-height: 1.3;
    // }
    // .rs-banner.style9 {
    //     min-height: 800px;
    // }
    // .rs-banner.style9 .banner-content {
    //     max-width: 535px;
    //     margin-left: 50px;
    // }
    // .rs-banner.style9 .banner-content .desc {
    //     font-size: 17px;
    //     line-height: 28px;
      
    // }
    // .rs-banner.style9 .shape-img{
    //     display: none;
    // }
    // .rs-banner.style13 .banner-content .title {
    //     font-size: 44px;
    //     line-height: 60px;
    //     margin-bottom: 30px;
    // }
    // .rs-banner.style13 .banner-img img {
    //     position: relative;
    //     max-width: 689px;
    //     right: 5%;
    //     bottom: -8px;
    // }
    // .rs-banner.style7 .banner-content .banner-title {
    //     font-size: 53px;
    //     line-height: 1.3;
    // }
    // .rs-banner.style7 .img-part {
    //     max-width: 600px;
    // }
    // .rs-banner.style7 .icons{
    //     display: none;
    // }
    .rs-banner.style6 .shape-img.left {
        left: -35px;
        max-width: 230px;
    }
    .rs-banner.style6 .shape-img.right {
        right: -50px;
        max-width: 225px;
    }
    .rs-banner.style4 {
        height: 850px;
    }
    .rs-banner.style4 .banner-content .banner-title {
        font-size: 55px;
    }
    // .rs-banner.style2 .banner-content .sub-title {
    //     font-size: 35px;
    //     line-height: 1.2;
    //     margin-bottom: 15px;
    // }
    // .rs-banner.style2 .banner-content .banner-title {
    //     font-size: 75px;
    //     line-height: 1.2;
    //     margin-bottom: 30px;
    // }
    // .rs-banner.style3 {
    //     min-height: 500px;
    // }
    // .rs-banner.style3 .banner-content .banner-image img {
    //     max-width: 500px;
    // }
    // .rs-banner.style3 .banner-content .banner-title {
    //     font-size: 58px;
    // }
    // .rs-banner.style3 .banner-content .banner-desc br {
    //     display: none;
    // }
    .rs-banner.style5 {
        min-height: 350px;
    }
    // .rs-banner.style5 .banner-content {
    //     // padding-bottom: 160px;
    // }
    .rs-banner.style5 .banner-content .banner-title {
        font-size: 30px;
    }
    .rs-banner .left-shape {
        top: 100px;
    }
    // .rs-banner.style1 .banner-content .banner-title {
    //     font-size: 45px;
    // }
    // .rs-banner.style12 .banner-img img {
    //     max-width: unset;
    //     width: 450px;
    // }
    
}

/* Tablet desktop :992px to 1199px. */
@media #{$only_lg} {
    // .rs-banner.style12 .banner-content .title {
    //     font-size: 30px;
    //     line-height: 40px;
    //     margin-bottom: 20px;
    // }
    
}

/* Tablet desktop :991px. */
@media #{$md} {
    // .rs-banner.style11 .content-part .title {
    //     font-size: 39px;
    //     line-height: 53px;
    // }
    // .rs-banner.style11 .images-part img {
    //     width: 380px;
    // }
    // .rs-banner.style11 {
    //     min-height: 800px;
    // }
    // .rs-banner.style3 {
    //     height: 650px !important;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap [type="search"] {
    //     width: 413px;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap button {
    //     right: 43%;
    // }
    // .rs-banner.style13 .banner-content {
    //     padding: unset;
    // }
    // .rs-banner.style11 .icons-spiner .squre-img {
    //     right: 58%;
    // }
    // .rs-banner.style13 {
    //     padding: 100px 0px 0px 0px;
    // }
    // .rs-banner.style13 .banner-img img {
    //     max-width: 100%;
    //     right: unset;
    // }
    // .rs-banner.style12 .banner-content .title {
    //     font-size: 41px;
    //     line-height: 51px;
    //     margin-bottom: 25px;
    // }
    // .rs-banner.style12 {
    //     padding: 72px 0px;
    // }
    // .rs-banner.style12 .banner-img img {
    //     width: 100%;
    // }
    .rs-banner.style10 {
        min-height: 100vh;
    }
    .rs-banner.style10 .banner-intro-box .shape-img {
        top: 100px;
    }
    .rs-banner.style10 .banner-intro-box .intro-img img {
        display: block;
    }
    // .rs-banner.style13 .rs-animation-part,
    .rs-banner.style10 .img-part {
       display: block;
      
    }
    .rs-banner.style10 .banner-intro-box .intro-img {
        position: absolute;
        right: 0%;
        top: 60px;
    }
    // .rs-banner.style9 {
    //     min-height: 750px;
    // }
    // .rs-banner.style9 .banner-content .desc {
    //     font-size: 16px;
    //     line-height: 28px;
    // }
    // .rs-banner.style9 .banner-content .banner-title {
    //     font-size: 50px;
    //     line-height: 1.2;
    // }
    // .rs-banner.style9 .banner-content {
    //     max-width: 510px;
    //     margin-left: 50px;
    // }
    // .rs-banner.style9 .social-icon .icon-cart li i {
    //     font-size: 16px;
    // }
    // .rs-banner.style9 .social-icon .icon-cart li a {
    //     width: 33px;
    //     height: 33px;
    //     line-height: 36px;
    
    // }
    // .rs-banner.style8 {
    //     min-height: 800px;
    // }
    // .rs-banner.style7 .banner-content .banner-title {
    //     font-size: 45px;
    //     line-height: 1.3;
    //     font-weight: 800;
    // }
    // .rs-banner.style7 .banner-content {
    //     max-width: 400px;
    // }
    // .rs-banner.style7 .icons{
    //     display: none;
    // }
    // .rs-banner.style7 .img-part {
    //     max-width: 415px;
    //     bottom: 213px;
    // }
    // .rs-banner.style7 {
    //     min-height: 500px;
    // }
    .rs-banner.style6 .banner-content {
        padding: 182px 0 338px;
    }
    .rs-banner.style6 .banner-content .desc {
        font-size: 18px;
        line-height: 25px;
    }
    .rs-banner.style6 .banner-content .banner-title {
        line-height: 70px;
        font-size: 50px;
    }
    .rs-banner.style6 .shape-img.left {
        left: -90px;
        max-width: 200px;
    }
    .rs-banner.style6 .shape-img.right {
        right: -90px;
        max-width: 200px;
    }
    .rs-banner.style6 .shape-img.center .inner {
        width: 600px;
        height: auto;
    }
    // .rs-banner.style1 .banner-content .banner-title {
    //     font-size: 40px;
    // }
    // .rs-banner.style2 .banner-content .sub-title {
    //     font-size: 30px;
    // }
    // .rs-banner.style2 .banner-content .banner-title {
    //     font-size: 60px;
    // }
    // .rs-banner.style3 .banner-content .banner-title {
    //     font-size: 50px;
    // }
    .rs-banner.style4 {
        height: 600px !important;
    }
    .rs-banner.style4 .banner-content .banner-title {
        font-size: 45px;
    }
    // .rs-banner.style5 {
    //     height: 80vh;   
    // }

    .rs-banner.style5 .banner-content {
        width: 100%;
        margin-left: 0px;
        text-align: center;
    }
    .rs-banner.style5 .banner-content .sub-title {
        font-size: 22px;
        // margin-bottom: 20px;
    }
    .rs-banner.style5 .banner-content .banner-title {
        font-size: 35px;
        margin-block: 30px;
    }

    .rs-banner.style5 .banner-img{
        position: static;
        width: 100%;
        // align-content: center;
        display: flex;
        justify-content: center;
        top: 0px;
    }
    // .re-banner.style5 {
    //     min-height : 100vh;
    // }
    // .rs-banner.style5 .banner-content {
    //     padding-bottom: 130px;
    // }
    .rs-banner.style5 .banner-img img {
        max-height: 315px;
    }

    .rs-banner.BannerAbout {
        height: 120vh;
    }

    .rs-banner.BannerAbout .banner-content {
        width: 100%;
        padding-bottom: 0px;
        margin-top: 245px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .rs-banner.BannerAbout .banner-content .sub-title {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .rs-banner.BannerAbout .banner-content .banner-title {
        font-size: 35px;
        margin-bottom: 10px;
    }

    .rs-banner.BannerAbout .banner-img{
        position: absolute;
        width: 100%;
        // align-content: center;
        display: flex;
        justify-content: center;
        top: 20px;
    }
    // .re-banner.style5 {
    //     min-height : 100vh;
    // }
    // .rs-banner.style5 .banner-content {
    //     padding-bottom: 130px;
    // }
    .rs-banner.BannerAbout .banner-img img {
        max-height: 300px;
    }
    .rs-banner.schoolBanner {
        height: 700px;
    }
    .rs-banner.BannerResearch {
        min-height: 370px;
    }
    .rs-banner.BannerResearch .banner-content .banner-title {
        font-size: 20px;
        line-height: 26px;
    }
    // .rs-banner.BannerResearch .teacher-image {
    //     // width: 300px;
    //     // position: absolute;
    //     //  top: 15px; 
    //     // overflow: hidden;
    //     // bottom: 150px;
    //     // right: -50px;
    // }
    .rs-banner.BannerResearch .banner-content {
        position: absolute;
        top: 100px;
        right: 30px;
        text-align: center;
    }
    // .rs-banner.BannerMath {
    //     min-height: 245px;
    // }
    .rs-banner.BannerMath .banner-content .banner-title {
        font-size: 25px;
        line-height: 30px;
    }
    // .rs-banner.BannerMath .teacher-image {
    //     width: 200px;
    //     position: absolute;
    //     //  top: 15px; 
    //     overflow: hidden;
    //     // bottom: 150px;
    //     right: -50px;
    // }
    // .rs-banner.BannerMath .banner-content {
    //     position: absolute;
    //     top: 100px;
    //     // text-align: center;
    // }

    .rs-banner.teacherBanner {
        height: 320px;
    }
    .rs-banner.teacherBanner .teacher-image img {
        height : 270px
    }
    .rs-banner.teacherBanner .teacher-image{
        position: absolute;
        top:50px;
        right: -250px;
        // width: 600;
        // align-content: center;
        display: flex;
        justify-content: center;
    }

    .rs-banner.teacherBanner .banner-content {
        position: absolute;
            top: 50px;
    }
    .rs-banner.teacherBanner .banner-content .sub-title {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .rs-banner.teacherBanner .banner-content .banner-title {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 1px;
    }

    .rs-banner.franchiseBanner .banner-content {
        position: absolute;
        bottom: 40px;
        left: 0px;
    }

    
}

/* Tablet :767px. */
@media #{$sm} {

    // .rs-banner.franchiseBanner {
    //     // height: 230px
    // }
 
    .rs-banner.franchiseBanner .banner-content {
        position: absolute;
        // top: 0px;
        bottom: 40px;
        left: 0px;
        padding-inline: 30px;
        .banner-title { 
            font-size: 25px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 30px;
        }
        .desc{
            font-size: 18px;
            font-weight: 500;
        }
    }

    // .rs-banner.style11 .icons-spiner .dot-img img {
    //     width: 120px;
    // }
    // .rs-banner.style11 .icons-spiner .dot-img {
    //     right: 7%;
    //     top: 26%;
    // }
    // .rs-banner.style11 .icons-spiner .squre-img {
    //     right: 49%;
    // }
    // .rs-banner.style11 {
    //     min-height: 700px;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap button {
    //     right: 24%;
    // }
    // .rs-banner.style13 .banner-content .title {
    //     font-size: 40px;
    //     line-height: 54px;
    //     margin-bottom: 18px;
    // }
    // .rs-banner.style11 .content-part .title {
    //     font-size: 27px;
    //     line-height: 45px;
    // }
    // .rs-banner.style11 .content-part .sub-title {
    //     font-size: 15px;
    //     line-height: 26px;
    //     margin-bottom: 12px;
    // }
    // .rs-banner.style11 {
    //     min-height: 750px;
    // }
    // .rs-banner.style11 .images-part img {
    //     width: 280px;
    // }
    // .rs-banner.style12 .banner-content .title {
    //     font-size: 30px;
    //     line-height: 40px;
    // }
    .rs-banner.style10 .banner-content .sl-title {
        font-size: 38px;
        line-height: 52px;
    }
    .rs-banner.style10 .banner-content .sl-sub-title {
        font-size: 18px;
        line-height: 23px;
    }
    // .rs-banner.style8 .banner-content .sl-sub-title {
    //     font-size: 34px;
    //     line-height: 1.2;
    // }
    // .rs-banner.style8 .banner-content .sl-title {
    //     font-size: 50px;
    //     line-height: 1.2;
    //     padding-right: 64px;
    // }
    // .rs-banner.style8 {
    //     min-height: 700px;
    // }
    // .rs-banner.style7 {
    //     min-height: 550px;
    // }
    // .rs-banner.style7 .banner-content {
    //     max-width: 100%;
    //     padding: 150px 0 0;
    // }
    // .rs-banner.style7 .banner-content .banner-title {
    //     font-size: 34px;
    //     line-height: 48px;      
    // }
    // .rs-banner.style7 .img-part {
    //     display: none;
    // }
    .rs-banner.style6 .banner-content .banner-title {
        line-height: 45px;
        font-size: 30px;
    }
    // .rs-banner.style1 .banner-content .banner-title {
    //     font-size: 35px;
    // }
    // .rs-banner.style2 {
    //     height: auto;
    //     min-height: 500px;
    // }
    // .rs-banner .banner-content .desc br,
    // .rs-banner.style3 .banner-content .banner-desc br {
    //     display: none;
    // }
    // .rs-banner.style2 .banner-content .banner-title {
    //     font-size: 50px;
    // }
    // .rs-banner.style3 .banner-content .banner-title {
    //     font-size: 40px;
    // }
    
    .rs-banner.style4 {
        height: 650px !important;
    }
    .rs-banner.style4 .banner-content .banner-title {
        font-size: 30px;
    }


    // .rs-banner.style5 {
    //     height: 80vh;
    // }

    .rs-banner.style5 .banner-content {
        width: 100%;
        // padding-bottom: 0px;
        // margin-top: 300px;
        // text-align: center;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // position: absolute;
        // bottom: 100px;
    }
    .rs-banner.style5 .banner-content .sub-title {
        font-size: 22px;
        margin-bottom: 0px;
    }
    .rs-banner.style5 .banner-content .banner-title {
        font-size: 35px;
        margin-block: 30px;
    }
    .rs-banner.style5 .banner-content .banner-btn {
        margin-block: 30px;
    }
    .rs-banner.style5 .banner-content .sub-title2 {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .rs-banner.style5 .banner-img{
        position: static;
        width: 100%;
        // align-content: center;
        display: flex;
        justify-content: center;
        top: 0px;
    }
   
    .rs-banner.style5 .banner-img img {
        max-height: 315px;
    }
    .rs-banner.style6 .banner-content .banner-title {
        margin-bottom: 15px;
    }
    .rs-banner.style6 .banner-content .desc {
        margin-bottom: 30px;
    }
    .rs-banner.style6 .banner-content {
        padding: 50px 0 50px;
    }
    .rs-banner.style6 .shape-img.left {
        left: -25px;
        max-width: 160px;
    }
    .rs-banner.style6 .shape-img.right {
        right: -70px;
        max-width: 150px;
    }
    .rs-banner.style6 .shape-img.center .inner {
        width: 500px;
    }
    .rs-banner.schoolBanner {
        height: 650px;
    }
    .rs-banner.BannerResearch {
        min-height: 600px;
    }
    .rs-banner.BannerResearch .banner-content .banner-title {
        font-size: 20px;
        line-height: 26px;
    }
    .rs-banner.BannerResearch .teacher-image {
        width: 300px;
        position: relative;
         top: -140px; 
        overflow: hidden;
        // bottom: 150px;
        // right: -50px;
    }
    .rs-banner.BannerResearch .banner-content {
        position: absolute;
        top: 240px;
        // text-align: center;
    }

    .rs-banner.BannerMath {
        min-height: 245px;
    }
    .rs-banner.BannerMath .banner-content .banner-title {
        font-size: 25px;
        line-height: 30px;
    }
    .rs-banner.BannerMath .teacher-image {
        width: 200px;
        position: absolute;
        //  top: 15px; 
        overflow: hidden;
        // bottom: 150px;
        right: -50px;
    }
    .rs-banner.BannerMath .banner-content {
        position: absolute;
        top: 100px;
        // text-align: center;
    }
    .rs-banner.BannerAbout {
        height: 450px;
    }
    .rs-banner.BannerAbout .banner-img img {
        max-height : 200px
    }
    .rs-banner.BannerAbout .banner-img{
        position: absolute;
        width: 100%;
        // align-content: center;
        display: flex;
        justify-content: center;
        top: 40px;
    }

    .rs-banner.BannerAbout .banner-content {
        width: 100%;
        padding-bottom: 0px;
        margin-top: 160px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .rs-banner.BannerAbout .banner-content .sub-title {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 20px;
    }
    .rs-banner.BannerAbout .banner-content .banner-title {
        font-size: 35px;
        margin-bottom: 1px;
    }

    .rs-banner.teacherBanner {
        height: 500px;
    }
    .rs-banner.teacherBanner .teacher-image img {
        height : 250px
    }
    .rs-banner.teacherBanner .teacher-image{
        position: absolute;
        top:-25px;
        right: -160px;
        // width: 600;
        // align-content: center;
        display: flex;
        justify-content: center;
    }

    .rs-banner.teacherBanner .banner-content {
        width: 100%;
        padding-bottom: 0px;
        margin-top: 220px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .rs-banner.teacherBanner .banner-content .sub-title {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .rs-banner.teacherBanner .banner-content .banner-title {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 1px;
    }
    
}

/* Large Mobile :575px. */
@media #{$xs} {

    // .rs-banner.franchiseBanner {
    //     height: 210px
    // }
    // .rs-banner.style8 .banner-content .sl-title {
    //     padding-right: 0;
    // }
    .rs-banner.style4 {
        height: 550px !important;
    }
    // .rs-banner.style11 .icons-spiner .spine2 img {
    //     width: 70px;
    // } 
    // .rs-banner.style11 .icons-spiner .circle img {
    //     width: 70px;
    // }
    // .rs-banner.style11 .images-part {
    //     display: none;
    // }
    // .rs-banner.style11 .icons-spiner .dot-img {
    //     display: none;
    // }
    // .rs-banner.style11 .content-part {
    //     padding: 180px 20px 100px;
    // }
    // .rs-banner.style11 .content-part .title {
    //     padding-right: 95px;
    // }
    // .rs-banner.style11 {
    //     min-height: 650px;
    // }
    // .rs-banner.style11 .icons-spiner .squre-img {
    //     right: 38%;
    // }
    // .rs-banner.style9 {
    //     min-height: 700px;
    // }
    // .rs-banner.style9 .banner-content {
    //     max-width: 400px;
    //     margin-left: 50px;
    // }      
    // .rs-banner.style9 .banner-content {
    //     max-width: 323px;
    //     margin-left: 50px;
    // }    
    // .rs-banner.style9 .banner-content .banner-title {
    //     font-size: 42px;
    //     line-height: 1.2;
    // }
    // .rs-banner.style9 .social-icon {
    //     bottom: 25px;
    //     left: 59px;
    // }
    // .rs-banner.style8 {
    //     min-height: 600px;
    // }
    // .rs-banner.style7 .img-part {
    //     display: none;
    // }
    // .rs-banner.style7 .banner-content {
    //     margin: 0 auto;
    // }
    // .rs-banner.style1 .banner-content .banner-title {
    //     font-size: 30px;
    // }
    .rs-banner.BannerAbout {
        height: 470px;
    }
    .rs-banner.BannerAbout .banner-img img {
        max-height : 200px
    }
    .rs-banner.BannerAbout .banner-img{
        position: absolute;
        width: 100%;
        // align-content: center;
        display: flex;
        justify-content: center;
        top: 40px;
    }

    .rs-banner.BannerAbout .banner-content {
        width: 100%;
        padding-bottom: 0px;
        margin-top: 150px;
        left: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .rs-banner.BannerAbout .banner-content .sub-title {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .rs-banner.BannerAbout .banner-content .banner-title {
        font-size: 35px;
        margin-bottom: 1px;
    }
    // .rs-banner.style5 {
    //     // height: 80vh;
    // }

    .rs-banner.style5 .banner-content {
        width: 100%;
        // padding-bottom: 0px;
        // margin-top: 300px;
        // text-align: center;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // position: absolute;
        // top: 250px;
    }
    .rs-banner.style5 .banner-content .sub-title {
        font-size: 22px;
        margin-bottom: 0px;
    }
    .rs-banner.style5 .banner-content .banner-title {
        font-size: 35px;
        margin-block: 30px;
    }
    .rs-banner.style5 .banner-content .banner-btn {
        margin-block: 30px;
    }
    .rs-banner.style5 .banner-content .sub-title2 {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .rs-banner.style5 .banner-img{
        position: static;
        width: 100%;
        // align-content: center;
        display: none;
        // justify-content: center;
        // top: 0px;
    }
   
    .rs-banner.style5 .banner-img img {
        max-height: 315px;
    }
    // .rs-banner.style2 .banner-content .sub-title {
    //     font-size: 20px;
    //     margin-bottom: 10px;
    // }
    // .rs-banner.style2 .banner-content .banner-title {
    //     font-size: 33px;
    //     margin-bottom: 25px;
    // }
    // .rs-banner.style3 {
    //     min-height: 400px;
    // }
    .rs-banner.style4 .banner-content .banner-title {
        font-size: 35px;
    }
    // .rs-banner.style5 .banner-content {
    //     width: 100%;
    //     padding-bottom: 0px;
    //     margin-top: 160px;
    //     text-align: center;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    // }
    // .rs-banner.style5 .banner-content .sub-title {
    //     font-size: 18px;
    //     margin-bottom: 20px;
    // }
    // .rs-banner.style5 .banner-content .banner-title {
    //     font-size: 35px;
    //     margin-bottom: 10px;
    // }
    .rs-banner.style6 .shape-img.left,
    .rs-banner.style6 .shape-img.right {
        display: none;
    }
    .rs-banner.style6 .shape-img {
        bottom: 0;
    }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap [type="search"] {
    //     width: 392PX;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap button {
    //     right: 21%;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap button i::before {
    //     font-size: 17px;
    // }
    .rs-banner.style6{
        margin-bottom: 0;
        height: 230px;
    }
    .rs-banner.style6 .banner-content {
        padding: 50px 0 50px;
    }
    .rs-banner.style6 .banner-content .banner-title {
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    .rs-banner.style6 .banner-content .desc {
        margin-bottom: 20px;
    }
    .rs-about.video-img .shape-bg-image {
        margin-top: -50px;
    }
    .rs-banner.schoolBanner {
        height: 650px;
    }
    .rs-banner.schoolBanner .banner-content {
        padding-inline: 30px;
    }
    .rs-banner.schoolBanner .banner-content .sub-title {
        font-size: 18px;
    }

    .rs-banner.BannerResearch {
        min-height: 600px;
    }
    .rs-banner.BannerResearch .banner-content .banner-title {
        font-size: 20px;
        line-height: 26px;
    }
    .rs-banner.BannerResearch .teacher-image {
        width: 300px;
        position: relative;
         top: -135px; 
        overflow: hidden;
        // bottom: 150px;
        // right: -50px;
    }
    .rs-banner.BannerResearch .banner-content {
        position: absolute;
        top: 240px;
        // text-align: center;
    }
    .rs-banner.BannerMath {
        min-height: 630px;
    }
    .rs-banner.BannerMath .banner-content .banner-title {
        font-size: 30px;
        line-height: 35px;
    }
    .rs-banner.BannerMath .teacher-image {
        width: 300px;
        position: relative;
         top: 15px; 
        overflow: hidden;
        // bottom: 150px;
        // right: -50px;
    }
    .rs-banner.BannerMath .banner-content {
        position: absolute;
        top: 350px;
        // text-align: center;
    }

    .rs-banner.teacherBanner {
        height: 500px;
    }
    .rs-banner.teacherBanner .teacher-image img {
        height : 250px
    }
    .rs-banner.teacherBanner .teacher-image{
        position: absolute;
        top:-27px;
        right: -160px;
        // width: 600;
        // align-content: center;
        display: flex;
        justify-content: center;
    }

    .rs-banner.teacherBanner .banner-content {
        width: 100%;
        padding-bottom: 0px;
        margin-top: 200px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .rs-banner.teacherBanner .banner-content .sub-title {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .rs-banner.teacherBanner .banner-content .banner-title {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 1px;
    }
   
    .rs-banner.style10 {
        min-height: 765px;
    }
    .rs-banner.style10 .banner-intro-box .intro-img {
        position: absolute;
        top: 57%;
        right: 10%;
    }
    .rs-banner.style10 .banner-intro-box .intro-img .img-part {
        position: relative;
        bottom: 350px;
        left: 35px;
        top: unset;
    }
}

/* small mobile :480px. */
@media #{$mobile} {
    .sec-title3 h2, .sec-title3 .title {
        font-size: 20px;
        line-height: 43px;
    }
    // .rs-banner.style11 .icons-spiner {
    //     display: none;
    // }
    // .rs-banner.style11 {
    //     min-height: 550px;
    // }
    .rs-banner.style4 {
        height: 450px !important;
    }
    // .rs-banner.style13 .banner-content .title {
    //     font-size: 28px;
    //     line-height: 41px;
    //     margin-bottom: 15px;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap button {
    //     right: -5px;
    //     top: 8px;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap [type="search"] {
    //     width: 100%;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap button i::before {
    //     font-size: 14px;
    // }
    // .rs-banner.style13 .banner-content .search-widget .search-wrap [type="search"] {
    //     padding: 20px 6px;
    //     font-size: 13px;
    // }
    // .rs-banner.style13 .banner-content .desc {
    //     font-size: 18px;
    //     line-height: 30px;
    //     margin-bottom: 33px;
    // }
    // .rs-banner.style13 .banner-content .sub-text {
    //     font-size: 14px;
    //     margin-bottom: 9px;
    // }
    // .rs-banner.style11 .icons-spiner .squre-img {
    //     right: 40%;
    //     bottom: 6%;
    // }
    // .rs-banner.style11 .content-part .title {
    //     padding-right: 0;
    // }
    // .rs-banner.style12 .banner-content .search-widget .search-wrap button {
    //     padding: 6px 13px 5px;
    //     right: -2px;
    //     top: 10px;
    // }
    // .rs-banner.style12 .banner-content .search-widget .search-wrap [type="search"] {
    //     padding: 15px 10px;
    //     padding-right: 30px;
    //     font-size: 13px;
    // }
    // .rs-banner.style12 .banner-content .title {
    //     font-size: 26px;
    //     line-height: 36px;
    // }
    // .rs-banner.style12 .banner-content .desc {
    //     font-size: 18px;
    //     line-height: 32px;
    // }
    .rs-banner.style10 .banner-content {
        padding: 0px 30px 30px;
        margin-top: -100px;
    }
    .rs-banner.style10 .banner-content .sl-title {
        font-size: 23px;
        line-height: 40px;
    }
    .rs-banner.style10 .banner-intro-box .shape-img img {
        width: 65px;
    }
    .rs-banner.style10 .banner-content .sl-sub-title {
        font-size: 17px;
        line-height: 23px;
    }
    .rs-banner.style10 {
        min-height: 450px;
    }
    // .rs-banner.style9 .banner-content .banner-title {
    //     font-size: 34px;
    //     line-height: 1.2;
    // }
    // .rs-banner.style9 .banner-content .desc {
    //     font-size: 15px;
    //     line-height: 28px;
    // }
    // .rs-banner.style9 {
    //     min-height: 600px;
    // }
    // .rs-banner.style9 .banner-content {
    //     max-width: 260px;        
    //     margin: 0 auto;
    // }
    // .rs-banner.style9 .social-icon {
    //     bottom: 16px;
    //     left: 40px;
    // }
    // .rs-banner.style8 {
    //     min-height: 500px;
    // }
    // .rs-banner.style8 .banner-content .sl-title {
    //     font-size: 32px;
    //     line-height: 1.2;
    //     padding-right: 0;
    // }
    // .rs-banner.style8 .banner-content .sl-sub-title {
    //     font-size: 22px;
    //     line-height: 1;
    // }
    // .rs-banner.style7 .banner-content .banner-title {
    //     font-size: 25px;
    //     line-height: 35px;
    // }

    // .rs-banner.style7 .banner-content {
    //     max-width: 300px;
    //     margin: 0 auto;
    //     padding: 124px 0 0;
    // }
    // .rs-banner.style1 .banner-content .banner-title {
    //     font-size: 25px;
    // }
    // .rs-banner.style1 .banner-content .desc {
    //     font-size: 18px;
    // }
    // .rs-banner.style3 .banner-content .banner-title {
    //     font-size: 30px;
    // }
    // .rs-banner.style3 .banner-content .banner-desc {
    //     font-size: 16px;
    // }
    // .rs-banner.style3 .banner-content .banner-btn li {
    //     display: block;
    //     margin: 0 0 15px;
    // }
    // .rs-banner.style3 .banner-content .banner-btn li .readon3 {
    //     padding: 10px 40px;
    //     font-size: 15px;
    // }
    .rs-banner.style4 .banner-content .banner-title {
        font-size: 30px;
    }
    .rs-banner.style4 .banner-content .desc {
        font-size: 18px;
    }
    // .rs-banner.style6 {
    //     // height: 400px;  
    // }
    .rs-banner.style6 .banner-content {
        padding: 20px 0 0px;
    }
    .rs-banner.style6 .shape-img.center .inner {
        width: 350px;
    }
    .rs-banner.style6 .banner-content .banner-title {
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    .rs-banner.style6 .banner-content .desc {
        margin-bottom: 20px;
    }
    .rs-banner.schoolBanner {
        height: 580px;
    }
    .rs-banner.schoolBanner .banner-content {
        padding-inline: 30px;
        padding-bottom: 50px;
    }
    .rs-banner.schoolBanner .banner-content .banner-title {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 25px;
    }
    .rs-banner.schoolBanner .banner-content .sub-title {
        font-size: 18px;
        line-height: 30px;
    }
    // .rs-banner.schoolBanner .banner-img { 
    //     // width: 200px;
    // }
    .rs-banner.BannerResearch {
        min-height: 650px;
    }
    .rs-banner.BannerResearch .banner-content .banner-title {
        font-size: 20px;
        line-height: 26px;
    }
    .rs-banner.BannerResearch .teacher-image {
        width:280px;
        position: relative;
        //  top: -150px; 
        //  left: 50px;
        overflow: hidden;
        // bottom: 150px;
        // right: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .rs-banner.BannerResearch .banner-content {
        position: absolute;
        top: 200px;
        // text-align: center;
    }

    .rs-banner.BannerMath {
        min-height: 630px;
    }
    .rs-banner.BannerMath .banner-content .banner-title {
        font-size: 30px;
        line-height: 35px;
    }
    .rs-banner.BannerMath .teacher-image {
        width: 300px;
        position: relative;
         top: 15px; 
        overflow: hidden;
        // bottom: 150px;
        // right: -50px;
    }
    .rs-banner.BannerMath .banner-content {
        position: absolute;
        top: 350px;
        left: unset;
        // text-align: center;
    }

    .rs-banner.teacherBanner {
        height: 520px;
    }
    .rs-banner.teacherBanner .teacher-image img {
        height : 200px
    }
    .rs-banner.teacherBanner .teacher-image{
        position: absolute;
        top:10px;
        right: -200px;
        // left: 0;
        // width: 600;
        // align-content: center;
        display: flex;
        justify-content: center;
    }

    .rs-banner.teacherBanner .banner-content {
        width: 100%;
        padding-bottom: 0px;
        margin-top: 200px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .rs-banner.teacherBanner .banner-content .sub-title {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .rs-banner.teacherBanner .banner-content .banner-title {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 1px;
    }
    .rs-banner.BannerAbout .banner-img img {
        max-height: 170px;
    }

    .rs-banner.whatAbacus .banner-content {
      padding-inline: 30px;
      position: absolute;
    top: 20px;
    }

    // .rs-banner.franchiseBanner{
    //     height: 150px;
    // }

    .rs-banner.franchiseBanner .banner-content {
        position: absolute;
        top: -140px;
        bottom: 0px;
        left: 0px;
        .banner-title {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        .desc{
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0px;
        }
    }

    // .rs-banner.style5 {
    //     // height:65vh;
    //     // background-image: none;
            
    // }

    .rs-banner.style5 .banner-content {
        width: 100%;
        // padding-bottom: 0px;
        // margin-top: 220px;
        // text-align: center;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // position: absolute;
        // top: 210px;
    }
    .rs-banner.style5 .banner-content .sub-title {
        font-size: 22px;
        margin-bottom: 0px;
    }
    .rs-banner.style5 .banner-content .banner-title {
        font-size: 35px;
        margin-block: 30px;
    }
    .rs-banner.style5 .banner-content .banner-btn {
        margin-block: 30px;
    }
    .rs-banner.style5 .banner-content .sub-title2 {
        font-size: 18px;
        margin-top: 0px;
    }

    .rs-banner.style5 .banner-img{
        // position: static;
        // width: 100%;
        display: none;
        // justify-content: center;
        // top: 0px;
    }
   
    .rs-banner.style5 .banner-img img {
        max-height: 315px;
    }
    .rs-banner.style10 {
        min-height: 765px;
    }
    .rs-banner.style10 .banner-intro-box .intro-img {
        position: absolute;
        top: 360px;
        right: unset;
    }
    .rs-banner.style10 .banner-intro-box .intro-img .img-part {
        position: relative;
        bottom: 350px;
        left: 35px;
        top: unset;
    }

    .rs-banner.BannerFranchiseFaq .banner-content .sub-title {
        // font-size: 20px;
        line-height: 40px;
        margin-top: -30px;
    }
    
}